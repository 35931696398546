<template>
  <div class="">
    <div class="row">
      <div id="turangchar1" class="chart-item"></div>
      <div id="turangchar2" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="turangchar3" class="chart-item"></div>
      <div id="turangchar4" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="turangchar5" class="chart-item"></div>
      <div id="turangchar6" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="turangchar7" class="chart-item"></div>
      <div id="turangchar8" class="chart-item"></div>
    </div>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';

import dayjs from 'dayjs';
import * as echarts from 'echarts';
export default {
  components: {},
  props: {
    data: 7
  },
  watch: {
    data: {
      handler(val) {
        this.startTime = dayjs()
          .subtract(val - 1, 'day')
          .format('YYYY-MM-DD');
        this.getData();
      },
      deep: true
    }
  },
  data() {
    return {
      startTime: '',
      endTime: ''
    };
  },
  created() {},
  mounted() {
    this.startTime = dayjs()
      .subtract(this.data - 1, 'day')
      .format('YYYY-MM-DD');
    this.endTime = dayjs().format('YYYY-MM-DD');
    this.getData();
  },
  methods: {
    getData() {
      postRequest('/api/iot/sq/getSqDetailByDate', { startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          this.chart(res['106'][0].dateDetailResponses, 'turangchar1', '土壤温度近七日变化-土壤深度0cm', 1);
          this.chart(res['107'][0].dateDetailResponses, 'turangchar2', '土壤湿度近七日变化-土壤深度0cm', 2);
          this.chart(res['106'][1].dateDetailResponses, 'turangchar3', '土壤温度近七日变化-土壤深度20cm', 1);
          this.chart(res['107'][1].dateDetailResponses, 'turangchar4', '土壤湿度近七日变化-土壤深度20cm', 2);
          this.chart(res['106'][2].dateDetailResponses, 'turangchar5', '土壤温度近七日变化-土壤深度30cm', 1);
          this.chart(res['107'][2].dateDetailResponses, 'turangchar6', '土壤湿度近七日变化-土壤深度30cm', 2);
          this.chart(res['106'][3].dateDetailResponses, 'turangchar7', '土壤温度近七日变化-土壤深度40cm', 1);
          this.chart(res['107'][3].dateDetailResponses, 'turangchar8', '土壤湿度近七日变化-土壤深度40cm', 2);
        })
        .catch((error) => {});
    },
    chart(list, id, title, subtitle) {
      if (!list.length) {
        return;
      }

      let option = {
        title: [
          {
            text: title,
            subtext: subtitle == 1 ? '单位：℃' : '单位：VOL%'
          }
        ],
        legend: {
          top: 40,
          data: list[0].deviceLevelDetails.map((e) => {
            return e.deviceName;
          })
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: list[0].deviceLevelDetails.map((e, index) => {
          console.log(index);
          return {
            name: e.deviceName,
            type: 'line',
            data: list.map((item) => {
              console.log(item.deviceLevelDetails);
              if (item.deviceLevelDetails && item.deviceLevelDetails[index]) {
                return item.deviceLevelDetails[index].value;
              }
              return 0;
            })
          };
        })

        //   [
        //   {
        //     name: list[0].deviceLevelDetails[0].deviceName,
        //     type: 'line',
        //     data: list.map(e => {
        //       return e.deviceLevelDetails[0].value;
        //     })
        //   },
        //   {
        //     name: list[0].deviceLevelDetails[1].deviceName,
        //     type: 'line',
        //     data: list.map(e => {
        //       if (e.deviceLevelDetails[1]) return e.deviceLevelDetails[1].value;
        //     })
        //   },
        //   {
        //     name: list[0].deviceLevelDetails[2].deviceName,
        //     type: 'line',
        //     data: list.map(e => {
        //       if (e.deviceLevelDetails[2]) return e.deviceLevelDetails[2].value;
        //     })
        //   },
        //   {
        //     name: list[0].deviceLevelDetails[3].deviceName,
        //     type: 'line',
        //     data: list.map(e => {
        //       if (e.deviceLevelDetails[3]) return e.deviceLevelDetails[3].value;
        //     })
        //   }
        // ]
      };
      this.setChart(id, option);
    },
    setChart(id, option) {
      var myChart = echarts.init(document.getElementById(id));

      myChart.setOption(option);
    }
  }
};
</script>
<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}
.chart-item {
  width: 49%;
  height: 260px;
  background-color: rgba(248, 248, 249, 1);
  padding: 12px;
  box-sizing: border-box !important;
}
</style>
