<template>
  <div class="">
    <div class="row">
      <div id="qixiangchar1" class="chart-item"></div>
      <div id="qixiangchar2" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="qixiangchar3" class="chart-item"></div>
      <div id="qixiangchar4" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="qixiangchar5" class="chart-item"></div>
      <div id="qixiangchar6" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="qixiangchar7" class="chart-item"></div>
      <div id="qixiangchar8" class="chart-item"></div>
    </div>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';

import dayjs from 'dayjs';
import * as echarts from 'echarts';
export default {
  components: {},
  props: {
    data: 7
  },
  watch: {
    data: {
      handler(val) {
        this.startTime = dayjs()
          .subtract(val - 1, 'day')
          .format('YYYY-MM-DD');
        this.getData();
      },
      deep: true
    }
  },
  data() {
    return {
      startTime: '',
      endTime: ''
    };
  },

  created() {},
  mounted() {
    this.startTime = dayjs()
      .subtract(this.data - 1, 'day')
      .format('YYYY-MM-DD');
    this.endTime = dayjs().format('YYYY-MM-DD');
    this.getData();
  },
  methods: {
    getData() {
      postRequest('/api/iot/szsw/chart', { deviceId: 17, startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          this.chart1(res.airtemperature);
          this.chart2(res.airhumidity);
          this.chart3(res.soiltemperature);
          this.chart4(res.soilhumidity);
          this.chart5(res.rainfall);
          this.chart6(res.windpower);
          this.chart7(res.lightintensity);
          this.chart8(res.winddirection);
        })
        .catch((error) => {});
    },
    chart1(list) {
      let option = {
        color: ['#02B98F'],
        tooltip: {
          trigger: 'axis'
        },
        title: [
          {
            text: `空气温度近${this.data}日变化`,
            subtext: '单位：℃'
          }
        ],
        legend: {
          data: ['空气温度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '空气温度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line'
          }
        ]
      };
      this.setChart('qixiangchar1', option);
    },
    chart2(list) {
      let option = {
        color: ['rgba(255, 116, 116, 1)'],
        tooltip: {
          trigger: 'axis'
        },
        title: [
          {
            text: `空气湿度近${this.data}日变化`,
            subtext: '单位：RH%'
          }
        ],
        legend: {
          data: ['空气湿度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '空气湿度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255,116,116, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(255,116,116, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('qixiangchar2', option);
    },
    chart3(list) {
      let option = {
        color: ['#5B8FF9'],
        title: [
          {
            text: `土壤温度近${this.data}日变化`,
            subtext: '单位：℃'
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['土壤温度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '土壤温度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line'
          }
        ]
      };
      this.setChart('qixiangchar3', option);
    },
    chart4(list) {
      let option = {
        color: ['rgba(250, 143, 0, 1)'],
        title: [
          {
            text: `土壤湿度近${this.data}日变化`,
            subtext: '单位：RH%'
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['土壤湿度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '土壤湿度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(250, 143, 0, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(250, 143, 0, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('qixiangchar4', option);
    },
    chart5(list) {
      let option = {
        title: [
          {
            text: `降水量近${this.data}日变化`,
            subtext: '单位：mm'
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['降水量']
        },
        color: ['#A28DFF'],
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '降水量',
            type: 'bar',
            barWidth: 20,
            data: list.map((e) => {
              return e.value;
            }),
            z: 10,
            zlevel: 0
          }
        ]
      };
      this.setChart('qixiangchar5', option);
    },
    chart6(list) {
      let option = {
        color: ['rgba(52, 203, 248, 1)'],
        title: [
          {
            text: `风速近${this.data}日变化`,
            subtext: '单位：m/s'
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['风速']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '风速',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(52, 203, 248, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(52, 203, 248, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('qixiangchar6', option);
    },
    chart7(list) {
      let option = {
        color: ['#F6BD16'],
        tooltip: {
          trigger: 'axis'
        },
        title: [
          {
            text: `光照强度近${this.data}日变化`,
            subtext: '单位：Klux'
          }
        ],
        legend: {
          data: ['光照强度']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '光照强度',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'bar'
          }
        ]
      };
      this.setChart('qixiangchar7', option);
    },
    chart8(list) {
      let option = {
        tooltip: {
          //雷达图的tooltip不会超出div，也可以设置position属性，position定位的tooltip 不会随着鼠标移动而位置变化，不友好
          confine: true,
          enterable: true //鼠标是否可以移动到tooltip区域内
        },
        title: [
          {
            text: `风向近${this.data}日变化`
          }
        ],
        legend: {
          data: ['天数'],
          right: 50,
          icon: 'circle'
        },
        radar: {
          name: {
            textStyle: {
              color: 'rgba(65, 97, 128, 0.45)',
              fontSize: 14
            }
          },
          shape: 'polygon',
          center: ['50%', '50%'],
          radius: '80%',
          startAngle: 120,
          scale: true,
          axisLine: {
            lineStyle: {
              color: 'rgba(65, 97, 128, 0.45)'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: 'rgba(65, 97, 128, 0.45)' // 设置网格的颜色
            }
          },
          indicator: list.map((e) => {
            return { name: e.direction, max: this.data };
          }),
          splitArea: {
            show: false
          }
        },
        grid: {
          position: 'center'
        },
        polar: {
          center: ['50%', '50%'], // 默认全局居中
          radius: '0%'
        },
        angleAxis: {
          min: 0,
          interval: 5,
          clockwise: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          min: 0,
          interval: 20,
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: '天数',
            type: 'radar',
            symbol: 'circle', // 拐点的样式，还可以取值'rect','angle'等
            symbolSize: 6, // 拐点的大小
            itemStyle: {
              normal: {
                color: 'rgba(91, 143, 249, 1)'
              }
            },
            areaStyle: {
              normal: {
                color: 'rgba(91, 143, 249, 1)',
                opacity: 0.5
              }
            },
            lineStyle: {
              width: 2,
              color: 'rgba(91, 143, 249, 1)'
            },
            label: {
              normal: {
                show: true,
                formatter: (params) => {
                  return params.value;
                },
                color: 'rgba(65, 97, 128, 0.45)'
              }
            },
            data: [
              {
                value: list.map((e) => {
                  return e.num;
                })
              }
            ]
          }
        ]
      };

      this.setChart('qixiangchar8', option);
    },
    setChart(id, option) {
      var myChart = echarts.init(document.getElementById(id));

      myChart.setOption(option);
    }
  }
};
</script>
<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}
.chart-item {
  width: 49%;
  height: 260px;
  background-color: rgba(248, 248, 249, 1);
  padding: 12px;
  box-sizing: border-box !important;
}
</style>
