<template>
  <div>
    <el-row style="margin-bottom: 12px"> </el-row>

    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :name="1" label="气象"></el-tab-pane>
      <el-tab-pane :name="2" label="水质水位"></el-tab-pane>
      <el-tab-pane :name="3" label="土壤墒情"></el-tab-pane>
      <el-tab-pane :name="4" label="虫情"></el-tab-pane>
    </el-tabs>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>

      <el-table-column v-if="activeName != 4" key="deviceName" prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column v-if="activeName != 4" key="deviceCode" prop="deviceCode" label="设备序列号"> </el-table-column>
      <el-table-column v-if="activeName != 4" key="date" prop="date" label="日期"> </el-table-column>

      <el-table-column v-if="activeName == 1" key="airhumidity" prop="airhumidity" label="空气湿度"> </el-table-column>
      <el-table-column v-if="activeName == 1" key="airtemperature" prop="airtemperature" label="空气温度"> </el-table-column>
      <el-table-column v-if="activeName == 1" key="lightintensity" prop="lightintensity" label="光照强度"> </el-table-column>
      <el-table-column v-if="activeName == 1" key="soiltemperature" prop="soiltemperature" label="土壤温度"> </el-table-column>
      <el-table-column v-if="activeName == 1" key="soilhumidity" prop="soilhumidity" label="土壤湿度"> </el-table-column>
      <el-table-column v-if="activeName == 1" key="rainfall" prop="rainfall" label="降雨量"> </el-table-column>
      <el-table-column v-if="activeName == 1" key="winddirection" prop="winddirection" label="风向"> </el-table-column>
      <el-table-column v-if="activeName == 1" key="windpower" prop="windpower" label="风速"> </el-table-column>

      <el-table-column v-if="activeName == 2" key="temperature" prop="temperature" label="水温"> </el-table-column>
      <el-table-column v-if="activeName == 2" key="ph" prop="ph" label="PH"> </el-table-column>
      <el-table-column v-if="activeName == 2" key="oxygen" prop="oxygen" label="溶氧"> </el-table-column>
      <el-table-column v-if="activeName == 2" key="waterDepth" prop="waterDepth" label="水位"> </el-table-column>

      <el-table-column v-if="activeName == 3" key="soiltemperature" prop="soiltemperature" label="土壤温度1"> </el-table-column>
      <el-table-column v-if="activeName == 3" key="soilhumidity" prop="soilhumidity" label="土壤湿度1"> </el-table-column>
      <el-table-column v-if="activeName == 3" key="soiltemperature2" prop="soiltemperature2" label="土壤温度2"> </el-table-column>
      <el-table-column v-if="activeName == 3" key="soilhumidity2" prop="soilhumidity2" label="土壤湿度2"> </el-table-column>
      <el-table-column v-if="activeName == 3" key="soiltemperature3" prop="soiltemperature3" label="土壤温度3"> </el-table-column>
      <el-table-column v-if="activeName == 3" key="soilhumidity3" prop="soilhumidity3" label="土壤湿度3"> </el-table-column>
      <el-table-column v-if="activeName == 3" key="soiltemperature4" prop="soiltemperature4" label="土壤温度4"> </el-table-column>
      <el-table-column v-if="activeName == 3" key="soilhumidity4" prop="soilhumidity4" label="土壤湿度4"> </el-table-column>

      <el-table-column v-if="activeName == 4" key="statisNum" prop="statisNum" label="杀虫数"> </el-table-column>
      <el-table-column v-if="activeName == 4" key="analysisDate" prop="analysisDate" label="杀虫日期"> </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { siqingHistory } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data() {
    return {
      activeName: 1,
      typeList: [
        { id: 1, name: '移动喷灌机' },
        { id: 0, name: '固定喷灌机' },
        { id: 2, name: '摄像头' },
        { id: 8, name: '指针喷灌机' },
        { id: 12, name: '水电双计' },
        { id: 13, name: '指针喷灌井' },
        { id: 15, name: '太阳能杀虫灯' }
      ],
      startDate: [],
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      (this.searchParams.startTime = this.startDate && this.startDate[0] ? dayjs(this.startDate[0]).format('YYYY-MM-DD HH:mm:ss') : ''),
        (this.searchParams.endTime = this.startDate && this.startDate[1] ? dayjs(this.startDate[1]).format('YYYY-MM-DD HH:mm:ss') : '');

      this.pageInfo.currentPage = 1;
      this.getList();
    },

    getList() {
      let url = '';
      switch (this.activeName) {
        case 1:
          url = '/api/iot/sq/qx/history';
          break;
        case 2:
          url = '/api/iot/sq/sw/history';
          break;
        case 3:
          url = '/api/iot/sq/tr/history';
          break;
        case 4:
          url = '/api/iot/cq/cq/history';
          break;
        default:
          break;
      }
      siqingHistory(url, { pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize, ...this.searchParams })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleClick() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
