<template>
  <div class="">
    <div class="row">
      <div id="shuizhichar1" class="chart-item"></div>
      <div id="shuizhichar2" class="chart-item"></div>
    </div>
    <div class="row">
      <div id="shuizhichar3" class="chart-item"></div>
      <div id="shuizhichar4" class="chart-item"></div>
    </div>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';

import dayjs from 'dayjs';
import * as echarts from 'echarts';
export default {
  components: {},
  props: {
    data: 7
  },
  watch: {
    data: {
      handler(val) {
        this.startTime = dayjs()
          .subtract(val - 1, 'day')
          .format('YYYY-MM-DD');
        this.getData();
      },
      deep: true
    }
  },
  data() {
    return {
      startTime: '',
      endTime: ''
    };
  },
  created() {},
  mounted() {
    this.startTime = dayjs()
      .subtract(this.data - 1, 'day')
      .format('YYYY-MM-DD');
    this.endTime = dayjs().format('YYYY-MM-DD');
    this.getData();
  },
  methods: {
    getData() {
      postRequest('/api/iot/szsw/chart', { deviceId: 19, startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          this.chart1(res.waterDepth);
          this.chart2(res.watertemperature);
          this.chart3(res.wateroxygen);
          this.chart4(res.waterph);
        })
        .catch((error) => {});
    },
    chart1(list) {
      let option = {
        color: ['#F6BD16'],
        title: [
          {
            text: `水位近${this.data}日变化`,

            subtext: '单位：米'
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['水位']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '水位',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'bar'
          }
        ]
      };
      this.setChart('shuizhichar1', option);
    },
    chart2(list) {
      let option = {
        color: ['#7BCB3F'],
        title: [
          {
            text: `水温近${this.data}日变化`,
            subtext: '单位：℃'
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['水温']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '水温',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line'
          }
        ]
      };
      this.setChart('shuizhichar2', option);
    },
    chart3(list) {
      let option = {
        color: ['#65789B'],
        title: [
          {
            text: `溶氧量近${this.data}日变化`,

            subtext: '单位：mg/L'
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['溶氧']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '溶氧',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'bar'
          }
        ]
      };
      this.setChart('shuizhichar3', option);
    },
    chart4(list) {
      let option = {
        title: [
          {
            text: `PH值近${this.data}日变化`
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['PH值']
        },
        xAxis: {
          type: 'category',
          data: list.map((e) => {
            return dayjs(e.date).format('MM-DD');
          })
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'PH值',
            data: list.map((e) => {
              return e.value;
            }),
            type: 'line',

            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(250, 142, 255, .25)'
                },
                {
                  offset: 1,
                  color: 'rgba(250, 142, 255, 0)'
                }
              ])
            }
          }
        ]
      };
      this.setChart('shuizhichar4', option);
    },

    setChart(id, option) {
      var myChart = echarts.init(document.getElementById(id));

      myChart.setOption(option);
    }
  }
};
</script>
<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}
.chart-item {
  width: 49%;
  height: 260px;
  background-color: rgba(248, 248, 249, 1);
  padding: 12px;
  box-sizing: border-box !important;
}
</style>
